// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-index-js": () => import("/Users/clyde/Desktop/ngo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-quiz-js": () => import("/Users/clyde/Desktop/ngo/src/pages/quiz.js" /* webpackChunkName: "component---src-pages-quiz-js" */)
}

exports.data = () => import("/Users/clyde/Desktop/ngo/.cache/data.json")

